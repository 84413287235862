<template>
    <div>
        <!--        <myBreadcrumb :nav="nav"></myBreadcrumb>-->
        <el-carousel height="464px">
            <el-carousel-item v-for="(item, index) in slideshowList" :key="index">
                <img class="lbt" :src="item.picUrl" alt="" />
            </el-carousel-item>
        </el-carousel>
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">预约类型：</span>
                    <el-select v-model="type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="left">
                <div class="col">
                    <span class="type">排序：</span>
                    <el-select v-model="type1" placeholder="请选择">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item" @click="$router.push({ name: 'excitingActivitiesDetail', query: { id: item.id } })" v-for="(item, index) in list" :key="index">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.name || '' }}</div>
                    <div class="desc" style="margin-top: 40px">时间：{{ item.createTime || '' }}</div>
                    <div class="desc">来源：{{ item.libraryName || '' }}</div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getHeritageActivityList" @current-change="getHeritageActivityList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getHeritageActivityList, getSlideshowList } from '@/api';
export default {
    name: 'index',
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            total: 10,
            list: [],
            nav: [
                {
                    title: '首页',
                    to: '/',
                },
                {
                    title: '推荐资源',
                },
                {
                    title: '学才艺',
                },
            ],
            slideshowList: [],
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    label: '演出',
                    value: 1,
                },
                {
                    label: '讲座',
                    value: 2,
                },
                {
                    label: '展览',
                    value: 3,
                },
                {
                    label: '会议',
                    value: 4,
                },
            ],
            options1: [
                {
                    value: '',
                    label: '默认',
                },
                {
                    label: '热门',
                    value: 1,
                },
                {
                    label: '最新发布',
                    value: 2,
                },
            ],
            type: '',
            type1: '',
        };
    },
    created() {
        this.getHeritageActivityList();
        getSlideshowList().then((res) => {
            console.log('轮播图', res);
            this.slideshowList = res.rows;
        });
    },
    watch: {
        type() {
            this.page = 1;
            this.list = [];
            this.getHeritageActivityList();
        },
        type1() {
            this.page = 1;
            this.list = [];
            this.getHeritageActivityList();
        },
    },
    methods: {
        getHeritageActivityList() {
            let params = {
                pageNum: this.page,
                pageSize: this.limit,
                type: 3,
                libraryId: localStorage.getItem('libraryId'),
            };
            if (this.type !== '') {
                params.activeType = this.type;
            }
            if (this.type1 !== '') {
                params.sortBy = this.type1;
            }
            this.$hao.getRecommendResource(params).then((res) => {
                console.log('学才艺', res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
.lbt {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 24px 0;
}
.row {
    display: flex;
    align-items: center;
    padding: 24px 0;
    .left {
        display: flex;
        align-items: center;
        .col {
            display: flex;
            align-items: center;
            margin-right: 24px;
            span {
                font-size: 13px;
                color: #333333;
            }
            .el-select {
                width: 160px;
            }
            ::v-deep .el-input__inner {
                border: 0;
            }
        }
    }
}
.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 100%;
                .ellipsisLine(1);
            }

            .desc {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                width: 100%;
                margin-top: 12px;
                .ellipsisLine(2);
            }
        }
    }
}
</style>
